import { Link } from "gatsby"
import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';

const DownloadTemplate = ({ data }) => {
  const { markdownRemark } = data;
  const { html } = markdownRemark;
  
  return (
    <Layout>
        <ul className="breadcrumb">
        <li><Link to="/">Home</Link></li>
       <li>Download</li>
        </ul>
      <section style={{margin:0.5+`em`,overflow:`hidden`}}>
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </section>
    </Layout>
  );
};

export default DownloadTemplate;



export const pageQuery = graphql`
  query($slug: String!) {
  	markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
    }
  }
}`;
